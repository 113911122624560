<template>
  <v-container>
    <h1>Service Locations</h1>
    <LocationsTable :locations="serviceLocations" :locationType="LocationTypes.SERVICE" />
  </v-container>
</template>
<script>
import { LocationTypes } from '@/js/LocationTypes'
import LocationsTable from '@/components/Locations/LocationsTable'
import { mapState } from 'vuex'

export default {
  name: 'ServiceLocations',
  components: { LocationsTable },
  data: () => ({
    LocationTypes,
    locations: []
  }),
  computed: {
    ...mapState({
      serviceLocations: state => state.Organization.serviceLocations
    })
  },
  mounted () {
    this.$store.dispatch('Organization/getServiceLocations')
  }
}
</script>
