export default {
  methods: {
    formatPhone (phone) {
      if (!phone) {
        return phone
      }

      const number = phone.number.toString()
      if (phone) {
        return `(${phone.area_code}) ${number.slice(0, 3)}-${number.slice(-4)}`
      }

      return ''
    }
  }
}
