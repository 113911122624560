<template>
  <v-container>
    <div>
      <v-btn small depressed fab color="primary" :to="{ name: 'CreateLocationView', params: { locationType: locationType.value }}">
        <v-icon small>fa-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table v-if="$vuetify.breakpoint.mdAndUp" :items="locations" :headers="headers">
      <template #item.address="{ item }">
        {{ formatAddress(item.address) }}
      </template>
      <template #item.phone_number="{ item }">
        {{ formatPhone(item.phone_number) }}
      </template>
      <template #item.actions="{ item }">
        <v-btn icon small :to="{ name: 'EditLocationView', params: { locationType: locationType.value, locationId: item.id }}">
          <v-icon small>far fa-edit</v-icon>
        </v-btn>
        <v-btn icon small @click="confirmDelete(item.id)">
          <v-icon small>fa-trash</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-list v-else>
      <v-list-item v-for="location in locations" :key="location.id" two-line :to="{ name: 'EditLocationView', params: { locationType: locationType.value, locationId: location.id  }}">
        <v-list-item-content>
          <v-list-item-title>{{ location.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ formatAddress(location.address) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>
<script>
import event, { Events } from '../../event'
import PhoneMixin from '../../mixins/PhoneMixin'
import AddressMixin from '../../mixins/AddressMixin'

export default {
  name: 'LocationsTable',
  mixins: [PhoneMixin, AddressMixin],
  props: {
    locations: {
      required: true,
      type: Array
    },
    locationType: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    deleteLocationId: null,
    headers: [
      { text: 'location', value: 'name' },
      { text: 'address', value: 'address' },
      { text: 'phone', value: 'phone_number', sortable: false },
      { text: 'actions', value: 'actions', sortable: false }
    ]
  }),
  created () {
    event.on(Events.CONFIRM_RESPONSE, (response) => {
      if (response) {
        this.deleteLocation()
      }
    })
  },
  methods: {
    confirmDelete (locationId) {
      this.deleteLocationId = locationId
      event.emit(Events.CONFIRM, 'Are you sure you want to delete this location? Location and all associated patient data will be archived.')
    },
    deleteLocation () {
      this.$store
        .dispatch('Organization/deleteLocation', { locationId: this.deleteLocationId, type: this.locationType.value })
        .then(() => {
          event.emit(Events.SUCCESS, 'Location successfully deleted')
        })
    }
  }
}
</script>
